// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKxrX_Ll-lmCVV0zZMr3TmdbHeCkxV1fw",
  authDomain: "dinowords-staging.firebaseapp.com",
  projectId: "dinowords-staging",
  storageBucket: "dinowords-staging.appspot.com",
  messagingSenderId: "119476509310",
  appId: "1:119476509310:web:2e0ff49a86444fd908962f",
  measurementId: "G-03VL0C9588",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const storage = getStorage();
const db = getFirestore();

export { db as default };

//export default getFirestore();
