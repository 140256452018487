import React, { useRef, useState, useEffect } from "react";
import Footer from "../Components/Footer.js";

import DinoBlack from "/Users/carloferrero/CREO Dropbox/Carlo Ferrero/Mac/Desktop/REACT/dinowords/src/Assets/dino_black.png";

//firestore
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
  addDoc,
} from "firebase/firestore";

import db from "../firebase";

const DinoWords = () => {
  const [words, setWords] = useState([]);
  const [word, setWord] = useState([]);
  const [count, setCount] = useState(0);
  const [availibility, setAvailibility] = useState("Type something");

  // GET THE DINOWORDS FROM FIRESTORE
  useEffect(() => {
    const q = query(collection(db, "dinowords"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      setWords(
        querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
    return unsub;
  }, []);

  // GET THE INPUT OF THE USER
  function HandleChange(e) {
    const inputWord = e.target.value;
    const uppercaseWord = inputWord.toUpperCase();
    wordAvailibility(uppercaseWord);
    const count = e.target.value;
    setCount(count.length);
    setWord(e.target.value);
  }
  const ItIsAvailible = () => {
    return (
      <>
        <p style={{ color: "#fff" }}>It is available!</p>
      </>
    );
  };
  const AlreadyTaken = () => {
    return (
      <>
        <p style={{ color: "#e05d5d" }}>Already taken</p>
      </>
    );
  };

  // CHECK IF IT IS AVAILIBLE
  function wordAvailibility(word) {
    const present = words.findIndex((e) => e.word === word);
    setAvailibility(present == -1 ? <ItIsAvailible /> : <AlreadyTaken />);
  }

  return (
    <div>
      <div className="py-40">
        <div className="section-transparent flex items-center">
          <div className="container mx-auto max-w-6xl">
            <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-20">
              <div className="flex justify-center md:justify-end">
                <img src={DinoBlack} alt="survive" className="w-64 md:w-96" />
              </div>
              <div className="text-center">
                <h2 className="text-black text-3xl md:text-3xl lg:text-4xl">
                  Already-minted “Last Dino-words”
                </h2>
                <p className="font-bold text-black text-lg md:text-xl lg:text-2xl text-center pt-5">
                  Not all the best sentence are already taken. But hush! And
                  remember: your last Dino words can take no longer than 20
                  characters. GOOD LUCK!
                </p>
              </div>
            </div>
          </div>
        </div>

        <p
          className="font-bold text-black text-lg md:text-xl lg:text-2xl text-center pt-10 pdb-5"
          style={{ color: "#D4FD43" }}
        >
          Type & Check
        </p>
        <div className="container mx-auto max-w-5xl text-center">
          <input
            type="text"
            placeholder="Dinowords"
            className="btn-main-border text-center"
            value={word}
            maxLength="20"
            style={{ textTransform: "uppercase" }}
            onChange={HandleChange}
          />
        </div>

        <p className="font-extrabold text-black text-lg md:text-xl lg:text-2xl text-center">
          {count}/20
        </p>
        <p className="font-extrabold text-black text-lg md:text-xl lg:text-2xl text-center pt-5 pb-10">
          {availibility}
        </p>
        <div className="container mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
            {words.map((item) => (
              <p
                key={item.id}
                className="font-bold font-KGHAPPY text-black text-lg md:text-xl lg:text-2xl sm:text-left pt-5"
              >
                {item.word} ({item.word.length})
                <br />
                <span className="font-bold font-karla text-green text-lg md:text-xl lg:text-2xl sm:text-left pt-7">
                  By {item.name}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DinoWords;
