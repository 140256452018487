/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/outline";

import { HashLink as Link } from "react-router-hash-link";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  return (
    <Popover className="absolute w-full transparent z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link to="/">
              <svg
                className="h-8 w-auto sm:h-10"
                xmlns="http://www.w3.org/2000/svg"
                width="121.111"
                height="101.795"
                viewBox="0 0 121.111 101.795"
              >
                <g
                  id="Raggruppa_1696"
                  data-name="Raggruppa 1696"
                  transform="translate(2197.921 2313.817)"
                >
                  <path
                    id="Sottrazione_1"
                    data-name="Sottrazione 1"
                    d="M-10681.178-11850.852h0c-.946-.314-1.819-.577-2.663-.83l-.077-.023-.016,0a25.67,25.67,0,0,1-9.344-4.364,8.2,8.2,0,0,1-2.567-2.945,4.862,4.862,0,0,1,.075-4.544,6.605,6.605,0,0,1,1.763-1.793l.013-.009a56.366,56.366,0,0,1,8.77-5.836c-1.784-2.734-3.818-6.127-5.885-9.811-1.418-2.535-2.707-4.954-3.629-6.813-1.635-3.3-1.635-3.821-1.635-4.205,0-.818.4-1.5,2.906-3.581,1.363-1.134,3.233-2.579,5.561-4.3,3.857-2.853,7.861-5.646,10.119-7.149a81.651,81.651,0,0,1-1.056-13.142c-.052-12.268,1.918-13.309,2.659-13.7a3.782,3.782,0,0,1,2.059-.383c2.321,0,6.55.658,12.929,2.012,3.981.843,7.724,1.735,9.6,2.192a89.729,89.729,0,0,1,5.107-10.041,62.739,62.739,0,0,1,4.376-6.628c1.724-2.214,2.945-3.159,4.084-3.159a2.039,2.039,0,0,1,.321.024c.848.129,1.875.771,4.373,3.572,1.411,1.582,3.2,3.765,5.317,6.487,3.077,3.95,5.9,7.835,6.7,8.932a62.246,62.246,0,0,0-11.833-1.131,50.575,50.575,0,0,0-18.459,3.523,53.486,53.486,0,0,0-15.521,9.349c-9.314,8.027-15.064,18.627-15.775,29.082-.607,8.932.081,18.917.953,31.56v.018q.059.869.12,1.752l.063.929c.092,1.354.188,2.754.281,4.172a1.365,1.365,0,0,0,.312.785Z"
                    transform="translate(8499 9636.592)"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                  />
                  <g
                    id="Raggruppa_1694"
                    data-name="Raggruppa 1694"
                    transform="translate(-2184.141 -2295.433)"
                  >
                    <path
                      id="Tracciato_3375"
                      data-name="Tracciato 3375"
                      d="M-2022.828-2119.568h0c9.535-4.7,16-13.78,16-24.214a25.376,25.376,0,0,0-4.431-14.27,4.526,4.526,0,0,1-.76-2.437c0-.073,0-.146-.01-.218-1.392-21.766-23.973-39.3-50.769-39.3-25.12,0-46.969,19.874-48.382,40.671-.706,10.382.383,22.539,1.419,38.246C-2109.763-2121.092-2023.251-2118.624-2022.828-2119.568Z"
                      transform="translate(2112.785 2201.386)"
                      opacity="0"
                    />
                    <path
                      id="Tracciato_3376"
                      data-name="Tracciato 3376"
                      d="M-2035.688-2124.7c-2.049,0-4.61-.018-7.649-.055-7.234-.087-17.286-.279-29.879-.569-21.436-.493-43.1-1.11-43.316-1.116a1.377,1.377,0,0,1-1.334-1.286q-.234-3.544-.465-6.853c-.871-12.662-1.56-22.663-.954-31.577.711-10.452,6.461-21.053,15.776-29.083a53.538,53.538,0,0,1,15.521-9.346,50.574,50.574,0,0,1,18.459-3.524,58.486,58.486,0,0,1,35.754,11.778c9.869,7.647,15.689,17.88,16.388,28.814.006.091.01.183.012.275a3.157,3.157,0,0,0,.525,1.7,26.817,26.817,0,0,1,4.666,15.04c0,10.555-6.367,20.267-16.627,25.381a3.623,3.623,0,0,1-1.722.346c-.658.034-1.623.057-2.868.069Q-2034.411-2124.7-2035.688-2124.7Zm4.874-2.157v0Zm0,0v0Zm0,0v0Zm.13-.226a1.351,1.351,0,0,0-.13.225A1.413,1.413,0,0,1-2030.681-2127.089Zm-84.522-2.067c32.838.931,81.327,2.088,85.119,1.589,9.344-4.639,15.145-13.421,15.145-22.937a24.072,24.072,0,0,0-4.195-13.5,5.9,5.9,0,0,1-1-3.175c0-.054,0-.108-.007-.161-.648-10.135-6.091-19.657-15.327-26.814a55.722,55.722,0,0,0-34.068-11.2,47.826,47.826,0,0,0-17.458,3.336,50.794,50.794,0,0,0-14.725,8.867c-8.761,7.553-14.166,17.461-14.826,27.184-.593,8.726.09,18.644.954,31.2Q-2115.4-2132.039-2115.2-2129.156Z"
                      transform="translate(2119.517 2208.109)"
                    />
                  </g>
                  <g
                    id="Raggruppa_1695"
                    data-name="Raggruppa 1695"
                    transform="translate(-2162.933 -2245.746)"
                  >
                    <circle
                      id="Ellisse_111"
                      data-name="Ellisse 111"
                      cx="10.336"
                      cy="10.336"
                      r="10.336"
                      transform="translate(0 0)"
                    />
                    <circle
                      id="Ellisse_112"
                      data-name="Ellisse 112"
                      cx="10.336"
                      cy="10.336"
                      r="10.336"
                      transform="translate(58.433 0)"
                    />
                  </g>
                  <circle
                    id="Ellisse_113"
                    data-name="Ellisse 113"
                    cx="3.312"
                    cy="3.312"
                    r="3.312"
                    transform="translate(-2140.914 -2257.127)"
                  />
                  <circle
                    id="Ellisse_114"
                    data-name="Ellisse 114"
                    cx="3.312"
                    cy="3.312"
                    r="3.312"
                    transform="translate(-2114.559 -2257.127)"
                  />
                </g>
              </svg>
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="transparent rounded-md p-2 inline-flex items-center justify-center text-black hover:text-black hover:transparent focus:outline-none focus:none">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Link
              to="/#about"
              className="text-lg font-bold text-black hover:text-black"
            >
              About Dinos
            </Link>
            <Link
              to="/#need-you"
              className="text-lg font-bold text-black hover:text-black"
            >
              We need you
            </Link>
            <Link
              to="/#faq"
              className="text-lg font-bold text-black hover:text-black"
            >
              FAQ
            </Link>
            <Link
              to="/#team"
              className="text-lg font-bold text-black hover:text-black"
            >
              Team
            </Link>
          </Popover.Group>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a href="https://opensea.io/collection/dinowords" target="_blank">
              <svg
                id="Raggruppa_1769"
                data-name="Raggruppa 1769"
                xmlns="http://www.w3.org/2000/svg"
                width="45.506"
                height="40.94"
                viewBox="0 0 45.506 40.94"
              >
                <path
                  id="Tracciato_3365"
                  data-name="Tracciato 3365"
                  d="M-469.382,1511.863l.156-.25,9.6-15.023a.324.324,0,0,1,.448-.1.349.349,0,0,1,.125.146c1.605,3.6,2.992,8.069,2.335,10.853a16.937,16.937,0,0,1-1.887,4.128,5.3,5.3,0,0,1-.365.615.328.328,0,0,1-.271.146h-9.873a.334.334,0,0,1-.334-.323A.665.665,0,0,1-469.382,1511.863Z"
                  transform="translate(472.093 -1488.5)"
                />
                <path
                  id="Tracciato_3366"
                  data-name="Tracciato 3366"
                  d="M-449.339,1446.469v2.377a.341.341,0,0,1-.208.313c-.74.313-3.284,1.491-4.347,2.961-2.7,3.753-4.764,9.133-9.372,9.133H-482.5a12.365,12.365,0,0,1-12.344-12.385v-.219a.336.336,0,0,1,.334-.334h10.728a.377.377,0,0,1,.354.375v.031a3.636,3.636,0,0,0,.386,2.064,3.777,3.777,0,0,0,3.378,2.1h5.306v-4.149H-479.6a.338.338,0,0,1-.334-.344.313.313,0,0,1,.063-.188c.052-.083.125-.177.188-.281.5-.709,1.209-1.8,1.908-3.044a22.405,22.405,0,0,0,1.324-2.648c.073-.167.136-.333.2-.49.1-.292.208-.552.281-.823.073-.23.136-.47.2-.688a10.47,10.47,0,0,0,.25-2.418c0-.334-.01-.678-.042-1-.01-.365-.063-.719-.1-1.084-.031-.323-.083-.636-.146-.959-.073-.48-.188-.959-.3-1.439l-.042-.187c-.094-.334-.167-.647-.271-.97-.3-1.032-.646-2.043-1.011-2.992q-.2-.563-.438-1.095c-.219-.542-.448-1.032-.657-1.5-.1-.209-.2-.406-.292-.6-.1-.219-.208-.448-.313-.657-.073-.167-.167-.313-.219-.469l-.646-1.2a.2.2,0,0,1,.073-.281.191.191,0,0,1,.167-.021l4.055,1.105h.021l.532.146.584.167.219.062v-2.408a2.089,2.089,0,0,1,2.085-2.106h0a2.049,2.049,0,0,1,1.47.615,2.1,2.1,0,0,1,.615,1.491v3.576l.427.125a.488.488,0,0,1,.1.052c.1.083.261.2.448.344.156.125.313.271.511.417.386.313.855.719,1.366,1.188.136.115.271.24.386.355.657.615,1.4,1.334,2.106,2.127.2.219.386.448.584.688s.407.48.584.719c.24.323.5.646.719.99.1.167.229.333.334.49.292.438.542.886.782,1.334.1.208.209.438.3.657a9.257,9.257,0,0,1,.615,1.835,1.872,1.872,0,0,1,.083.407v.031a3.442,3.442,0,0,1,.073.573,6.022,6.022,0,0,1-.1,1.887c-.062.271-.135.532-.219.792s-.188.521-.3.782a10.34,10.34,0,0,1-.813,1.543c-.1.177-.219.375-.344.553s-.271.375-.386.553a8.457,8.457,0,0,1-.532.678,7.718,7.718,0,0,1-.511.646c-.25.3-.5.584-.751.855-.156.177-.313.365-.479.521-.167.177-.334.344-.48.5-.25.25-.469.448-.646.615l-.417.385a.358.358,0,0,1-.219.084H-470.2v4.149h4.066a3.776,3.776,0,0,0,2.471-.917,28.125,28.125,0,0,0,2.513-2.471.3.3,0,0,1,.156-.094l11.238-3.242a.327.327,0,0,1,.407.229A.289.289,0,0,1-449.339,1446.469Z"
                  transform="translate(494.845 -1420.312)"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Link to="/">
                    <svg
                      className="h-8 w-auto sm:h-10"
                      xmlns="http://www.w3.org/2000/svg"
                      width="121.111"
                      height="101.795"
                      viewBox="0 0 121.111 101.795"
                    >
                      <g
                        id="Raggruppa_1696"
                        data-name="Raggruppa 1696"
                        transform="translate(2197.921 2313.817)"
                      >
                        <path
                          id="Sottrazione_1"
                          data-name="Sottrazione 1"
                          d="M-10681.178-11850.852h0c-.946-.314-1.819-.577-2.663-.83l-.077-.023-.016,0a25.67,25.67,0,0,1-9.344-4.364,8.2,8.2,0,0,1-2.567-2.945,4.862,4.862,0,0,1,.075-4.544,6.605,6.605,0,0,1,1.763-1.793l.013-.009a56.366,56.366,0,0,1,8.77-5.836c-1.784-2.734-3.818-6.127-5.885-9.811-1.418-2.535-2.707-4.954-3.629-6.813-1.635-3.3-1.635-3.821-1.635-4.205,0-.818.4-1.5,2.906-3.581,1.363-1.134,3.233-2.579,5.561-4.3,3.857-2.853,7.861-5.646,10.119-7.149a81.651,81.651,0,0,1-1.056-13.142c-.052-12.268,1.918-13.309,2.659-13.7a3.782,3.782,0,0,1,2.059-.383c2.321,0,6.55.658,12.929,2.012,3.981.843,7.724,1.735,9.6,2.192a89.729,89.729,0,0,1,5.107-10.041,62.739,62.739,0,0,1,4.376-6.628c1.724-2.214,2.945-3.159,4.084-3.159a2.039,2.039,0,0,1,.321.024c.848.129,1.875.771,4.373,3.572,1.411,1.582,3.2,3.765,5.317,6.487,3.077,3.95,5.9,7.835,6.7,8.932a62.246,62.246,0,0,0-11.833-1.131,50.575,50.575,0,0,0-18.459,3.523,53.486,53.486,0,0,0-15.521,9.349c-9.314,8.027-15.064,18.627-15.775,29.082-.607,8.932.081,18.917.953,31.56v.018q.059.869.12,1.752l.063.929c.092,1.354.188,2.754.281,4.172a1.365,1.365,0,0,0,.312.785Z"
                          transform="translate(8499 9636.592)"
                          stroke="rgba(0,0,0,0)"
                          strokeMiterlimit="10"
                          strokeWidth="1"
                        />
                        <g
                          id="Raggruppa_1694"
                          data-name="Raggruppa 1694"
                          transform="translate(-2184.141 -2295.433)"
                        >
                          <path
                            id="Tracciato_3375"
                            data-name="Tracciato 3375"
                            d="M-2022.828-2119.568h0c9.535-4.7,16-13.78,16-24.214a25.376,25.376,0,0,0-4.431-14.27,4.526,4.526,0,0,1-.76-2.437c0-.073,0-.146-.01-.218-1.392-21.766-23.973-39.3-50.769-39.3-25.12,0-46.969,19.874-48.382,40.671-.706,10.382.383,22.539,1.419,38.246C-2109.763-2121.092-2023.251-2118.624-2022.828-2119.568Z"
                            transform="translate(2112.785 2201.386)"
                            opacity="0"
                          />
                          <path
                            id="Tracciato_3376"
                            data-name="Tracciato 3376"
                            d="M-2035.688-2124.7c-2.049,0-4.61-.018-7.649-.055-7.234-.087-17.286-.279-29.879-.569-21.436-.493-43.1-1.11-43.316-1.116a1.377,1.377,0,0,1-1.334-1.286q-.234-3.544-.465-6.853c-.871-12.662-1.56-22.663-.954-31.577.711-10.452,6.461-21.053,15.776-29.083a53.538,53.538,0,0,1,15.521-9.346,50.574,50.574,0,0,1,18.459-3.524,58.486,58.486,0,0,1,35.754,11.778c9.869,7.647,15.689,17.88,16.388,28.814.006.091.01.183.012.275a3.157,3.157,0,0,0,.525,1.7,26.817,26.817,0,0,1,4.666,15.04c0,10.555-6.367,20.267-16.627,25.381a3.623,3.623,0,0,1-1.722.346c-.658.034-1.623.057-2.868.069Q-2034.411-2124.7-2035.688-2124.7Zm4.874-2.157v0Zm0,0v0Zm0,0v0Zm.13-.226a1.351,1.351,0,0,0-.13.225A1.413,1.413,0,0,1-2030.681-2127.089Zm-84.522-2.067c32.838.931,81.327,2.088,85.119,1.589,9.344-4.639,15.145-13.421,15.145-22.937a24.072,24.072,0,0,0-4.195-13.5,5.9,5.9,0,0,1-1-3.175c0-.054,0-.108-.007-.161-.648-10.135-6.091-19.657-15.327-26.814a55.722,55.722,0,0,0-34.068-11.2,47.826,47.826,0,0,0-17.458,3.336,50.794,50.794,0,0,0-14.725,8.867c-8.761,7.553-14.166,17.461-14.826,27.184-.593,8.726.09,18.644.954,31.2Q-2115.4-2132.039-2115.2-2129.156Z"
                            transform="translate(2119.517 2208.109)"
                          />
                        </g>
                        <g
                          id="Raggruppa_1695"
                          data-name="Raggruppa 1695"
                          transform="translate(-2162.933 -2245.746)"
                        >
                          <circle
                            id="Ellisse_111"
                            data-name="Ellisse 111"
                            cx="10.336"
                            cy="10.336"
                            r="10.336"
                            transform="translate(0 0)"
                          />
                          <circle
                            id="Ellisse_112"
                            data-name="Ellisse 112"
                            cx="10.336"
                            cy="10.336"
                            r="10.336"
                            transform="translate(58.433 0)"
                          />
                        </g>
                        <circle
                          id="Ellisse_113"
                          data-name="Ellisse 113"
                          cx="3.312"
                          cy="3.312"
                          r="3.312"
                          transform="translate(-2140.914 -2257.127)"
                        />
                        <circle
                          id="Ellisse_114"
                          data-name="Ellisse 114"
                          cx="3.312"
                          cy="3.312"
                          r="3.312"
                          transform="translate(-2114.559 -2257.127)"
                        />
                      </g>
                    </svg>
                  </Link>
                </div>
                <div className="-mr-2">
                  <Popover.Button className="transparent rounded-md p-2 inline-flex items-center justify-center text-vlack hover:text-grey hover:transparent focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6"></div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                <Link
                  to="/#about"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  About Dinos
                </Link>

                <Link
                  to="/#need-you"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  We need you
                </Link>
                <Link
                  to="/#faq"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  FAQ
                </Link>
                <Link
                  to="/#team"
                  className="text-base font-medium text-gray-900 hover:text-gray-700"
                >
                  Team
                </Link>
              </div>
              <div>
                <Link
                  to="/dinoWords"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green"
                >
                  Type your dinowords
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
