import "./App.css";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";

// Pages import
import Homepage from "./Pages/Homepage";
import ComingSoon from "./Pages/ComingSoon";
import DinoWords from "./Pages/DinoWords";
//import DinoWords from "./Pages/DinoWords";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/dinowords" element={<DinoWords />} />
      </Routes>
    </div>
  );
}

export default App;
